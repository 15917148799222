import React from "react"
import SinglePageHeader from "../../components/SinglePageLayout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function DirectInvestments() {
  const { t } = useTranslation()

  const companies = Array.from({ length: 9 }).map((_, i) => {
    const imageSrc = `/assets/img/di/${i + 1}.png`
    return (
      <div key={i}>
        <div className="overlay"></div>
        <img src={imageSrc} alt={i} />
        {/* <strong style={{ textAlign: "center" }}>{p.frontmatter.title}</strong>
        <span className="summary">{p.frontmatter.description}</span> */}
      </div>
    )
  })
  return (
    <SinglePageHeader
      title={t("direct-investments")}
      bg="/assets/img/portfolio-header.jpg"
    >
      <div className="container pd-top-100 pd-bottom-100">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title section-title-2 text-center">
              <h6 className="sub-title">{t("direct-investments")}</h6>
              <h2 className="title">{t("companies")}</h2>
            </div>
          </div>
        </div>
        <div className="portfolio-grid">{companies}</div>
      </div>
      <section className="ich-container bg-grey" style={{ maxWidth: "100%" }}>
        <div className="container" style={{ fontSize: 20 }}>
          <div className="row">
            <p>{t("di-intro-text")}</p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <ul>
                <li>{t("di-col-1-1")}</li>
                <li>{t("di-col-1-2")}</li>
                <li>{t("di-col-1-3")}</li>
                <li>{t("di-col-1-4")}</li>
                <li>{t("di-col-1-5")}</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul>
                <li>{t("di-col-2-1")}</li>
                <li>{t("di-col-2-2")}</li>
                <li>{t("di-col-2-3")}</li>
                <li>{t("di-col-2-4")}</li>
                <li>{t("di-col-2-5")}</li>
                <li>{t("di-col-2-6")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </SinglePageHeader>
  )
}
